/* make customizations */
//$primary: #f074d9;

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';

$GREEN_1: #00b44bff;
$GREEN_2: #3fc677ff;
$GREEN_3: #a1e2bcff;
$GREY_1: #9d9d9dff;
$GREY_2: #bcbcbcff;
$GREY_3: #fafafaff;
$BLUE_3: #a6d3deff;
$BLUE_2: #41a3bbff;
$BLUE_1: #0385a6ff;
$PURPLE_2: #3a3d99;
$PURPLE_1: #23235D;

.btn-green {
  background: $GREEN_2;
}

.btn-green:hover, .btn-blue:hover, .btn-green:active, .btn-blue:active, .btn-green:focus, .btn-blue:focus {
  background: $GREY_1 !important;
  color: white !important;
}

.btn-blue {
  background: $BLUE_2;
}

.vote-btn {
  width: 100%;
  height: 200px;
  padding: 10px;
  margin: 5px;
  font-size: 4.5em;
}

.countdown-circle {
  width: 150px;
  height: 150px;
  background-color: $PURPLE_1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  position: relative;
}

.countdown-circle-outer {
  background-color: $PURPLE_2;
  opacity: 0.35;
  position: absolute;
  z-index: -1;
}

.countdown-circle-terminated {
  top: 50%; /* align top edge of the child with the vertical center of the parent */
  left: 50%; /* align left edge of the child with the horizontal center of the parent */
  transform: translate(-50%, -50%); /* translate the child back by half its width and height */
  position: absolute;
}

.revealed-answer {
  font-size: 300pt;
  transform: translate(0, -25px) !important;
  color: $body-bg-dark;
  top: 0;
  left: 0;
}

.no-bottom-margin {
  margin-bottom: 0;
}

.mb-prompt {
  margin-bottom: 3vh;
}

.prompt-height {
  min-height: 2.4em; /* line-height is set to 1.2em by default*/
}

.pt-standard {
  padding-top: 11vh;
}

.qr-big {
  margin-top: 6vh;
  height: calc(85vh - 250px);
  max-height: calc(85vh - 250px);
  width: auto;
}

.qr-small {
  height: auto;
  width: 100%;
}

.mono-digits {
  font-variant-numeric: tabular-nums;
}