.vote-bar-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 100px
}

.vote-bar-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 60px;
}

.vote-bar-wrapper span {
    margin-top: 10px;
    font-weight: bold;
}

.vote-bar {
    height: calc(70vh - 225px);
    width: 6em;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
}

.vote-bar-progress {
    border-radius: 5px;
}